import {
  media,
  mq,
} from '@gameonsports/components/cjs/_utils/styled-components-utils'
import { Box } from '@gameonsports/components/cjs/Box'
import Icon from '@gameonsports/components/cjs/Icon'
import { Stack } from '@gameonsports/components/cjs/Stack'
import { Text } from '@gameonsports/components/cjs/Text'
import { Link, RouteComponentProps, navigate } from '@reach/router'
import { Formik } from 'formik-1.5.8'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import styled, { css } from 'styled-components'
import HomeSearchForm, {
  HomeSearchFormValidationSchema,
  HomeSearchFormValues,
} from '../../forms/HomeSearchForm'
import useFeatureFlagOn from '../../hooks/useFeatureFlagOn'
import aflImage from './images/afl.jpeg'
import aflImage2x from './images/afl@2x.jpeg'
import basketballImage from './images/basketball.jpeg'
import basketballImage2x from './images/basketball@2x.jpeg'
import changingTheGame from './images/changing-the-game.jpeg'
import court1x from './images/court@1x.jpeg'
import court2x from './images/court@2x.jpeg'
import cricketImage from './images/cricket.jpeg'
import cricketImage2x from './images/cricket@2x.jpeg'
import everysport from './images/everysport.jpeg'
import footballImage from './images/football.jpeg'
import footballImage2x from './images/football@2x.jpeg'
import hockeyImage from './images/hockey.jpeg'
import hockeyImage2x from './images/hockey@2x.jpeg'
import netballImage from './images/netball.jpeg'
import netballImage2x from './images/netball@2x.jpeg'

const Figure = styled.figure`
  position: relative;
  margin: 0;
  height: 100%;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0) 35.34%
    );
  }

  &::after {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 100%
    );

    a:hover & {
      opacity: 1;
    }
  }

  figcaption {
    position: absolute;
    bottom: 1.5rem;
    left: 1rem;
    z-index: 1;
  }
`

// NOTE: if columns is > 5, they will not fit in a single row, so it is turned
// into a 3-column grid that wraps.
const SportTileContainer = styled.div<{ $columnCount: number }>`
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  ${props =>
    props.$columnCount > 5 &&
    css`
      ${mq.up('tablet')} {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 0;
      }
    `}
`

interface SportCardProps {
  name: string
  image: string
  image2x: string
}

const SportCard = (props: SportCardProps) => {
  return (
    <Figure>
      <Box
        as="picture"
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="100%"
        height="100%"
        maxHeight={['24rem', null, '18rem']}
      >
        <source srcSet={`${props.image2x} 2x`} />
        <Box
          as="img"
          display="block"
          src={props.image}
          maxWidth="100%"
          width="100%"
          height="100%"
          objectFit="cover"
          alt=""
        />
      </Box>
      <Stack
        as="figcaption"
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="calc(100% - 2rem)"
      >
        <Text size="30" color="white400" weight="700">
          {props.name}
        </Text>
        <Icon name="right-arrow-strong" color="white400" />
      </Stack>
    </Figure>
  )
}

const SportLink = styled(Box).withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) => defaultValidatorFn(prop),
})`
  position: relative;
  transition: all 0.2s ease-in-out;
  border-radius: 0.75rem;
  overflow: hidden;

  &:hover {
    transform: translateY(-1px);
    filter: drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.2));
  }
`

const CourtBox = styled.section`
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url(${court1x});
  background-size: cover, cover;
  padding: 7rem 1rem;

  @media (min-resolution: 2dppx) {
    background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      url(${court2x});
  }

  ${media.tablet`
    padding-top: 11rem;
    padding-bottom: 11rem;
  `}

  ${media.desktop`
    padding-top: 12rem;
    padding-bottom: 12rem;
  `}
`

const Image = styled.img`
  border-radius: 0.5rem;

  ${media.tablet`
    width: 40%;
    order: ${(props: { order?: number }) => props.order};
  `}
`

const HomeLink = styled.a`
  background-color: ${props => props.theme.colors.grape400};
  color: ${props => props.theme.colors.white400};
  padding: 1rem 1.5rem;
  border-radius: 3rem;
  text-decoration: none;
  line-height: 1;
  font-weight: 700;
  transition: background-color 0.2s ease;

  svg {
    vertical-align: middle;
    margin-left: 1rem;
  }

  &:hover {
    background-color: ${props => props.theme.colors.blackberry400};
  }
`

interface Sport {
  name: string
  link: string
  image: string
  image2x: string
}

const Home = (_: RouteComponentProps) => {
  const isFootballOn = useFeatureFlagOn('layup-football-au')

  // TODO: load these from the api
  const sports: Sport[] = [
    { name: 'AFL', link: '/afl', image: aflImage, image2x: aflImage2x },
    {
      name: 'Basketball',
      link: '/basketball',
      image: basketballImage,
      image2x: basketballImage2x,
    },
    {
      name: 'Cricket',
      link: '/cricket',
      image: cricketImage,
      image2x: cricketImage2x,
    },
    {
      name: 'Hockey',
      link: '/hockey-new-zealand',
      image: hockeyImage,
      image2x: hockeyImage2x,
    },
    {
      name: 'Netball',
      link: '/netball-australia',
      image: netballImage,
      image2x: netballImage2x,
    },
    ...(isFootballOn
      ? [
          {
            name: 'Football',
            link: '/football-au',
            image: footballImage,
            image2x: footballImage2x,
          },
        ]
      : []),
  ]

  return (
    <>
      <Helmet
        title="Sports League Management, Registration & Scheduling"
        meta={[
          {
            name: 'description',
            content:
              'PlayHQ provides easy sports league management, team registration, and scheduling for AFL, basketball, cricket, and more. Discover seamless online tools for organisers, clubs, and players.',
          },
        ]}
      />
      <Stack
        gap="xxxl"
        maxWidth="62rem"
        width="100%"
        paddingX="m"
        paddingTop="m"
        paddingBottom="xl"
        marginX="auto"
      >
        <Stack gap="xl" paddingY="xl" justifyContent="center" width="100%">
          <Stack gap="m">
            <Text
              as="h1"
              weight="700"
              color="white400"
              size={['48', '80']}
              textAlign={['center', null, 'left']}
              lineHeight={1}
              family="Athletics-Black"
            >
              Get ready{' '}
              <Text display={['block', null, 'inline']} color="watermelon400">
                to play.
              </Text>
            </Text>
            <Text
              size={['20', null, '24']}
              textAlign={['center', null, 'left']}
              color="white400"
            >
              PlayHQ is the new home of community sport.
            </Text>
          </Stack>

          <Formik<HomeSearchFormValues>
            initialValues={{
              query: '',
            }}
            onSubmit={values => {
              return navigate(`/search?query=${values.query}`)
            }}
            validationSchema={HomeSearchFormValidationSchema}
          >
            {formProps => (
              <form onSubmit={formProps.handleSubmit} action="/search">
                <HomeSearchForm {...formProps} />
              </form>
            )}
          </Formik>
        </Stack>

        <Stack gap="xxl" paddingY="xxxl">
          <Text as="h2" size="36" family="Athletics-Black">
            <Text color="grape400">Find</Text> your Organisation
          </Text>
          <SportTileContainer $columnCount={sports.length}>
            {sports.map(sport => (
              <SportLink
                key={sport.name}
                as={Link}
                to={sport.link}
                margin="s"
                flexGrow={1}
                flexShrink={1}
                flexBasis={['auto', '33%', '0%']}
                maxWidth={[
                  '100%',
                  '50%',
                  sports.length > 5 ? '100%' : 'max-content',
                ]}
              >
                <SportCard
                  name={sport.name}
                  image={sport.image}
                  image2x={sport.image2x}
                />
              </SportLink>
            ))}
          </SportTileContainer>
        </Stack>
      </Stack>

      {/* Banners */}
      <Stack backgroundColor="black400" gap="xxxl" paddingY="xxxl" as="section">
        <div>
          <Stack
            gap="l"
            justifyContent="space-between"
            flexDirection={['column', 'column', 'row']}
            padding="m"
            maxWidth="62rem"
            width="100%"
            marginX="auto"
            marginTop={[null, null, 'l']}
            alignItems={[null, null, 'center']}
          >
            <Image src={changingTheGame} />
            <Stack
              gap="m"
              alignItems="flex-start"
              width={['100%', null, '40%']}
            >
              <Text as="h2" color="white400" family="Athletics-Black" size="32">
                <Text color="watermelon400">Changing</Text> the game.
              </Text>
              <Text as="p" color="white400">
                PlayHQ is on a mission to reinvent community sport technology,
                replacing decades of unreliable legacy systems and spreadsheets
                with a seamless, mobile-first digital experience that connects
                communities across sporting codes.
              </Text>
              <HomeLink href="/au/about-us" rel="noopener noreferrer">
                Find out more
              </HomeLink>
            </Stack>
          </Stack>
        </div>

        <div>
          <Stack
            gap="l"
            justifyContent="space-between"
            flexDirection={['column', 'column', 'row']}
            padding="m"
            maxWidth="62rem"
            width="100%"
            marginX="auto"
            marginBottom={[null, null, 'l']}
            alignItems={[null, null, 'center']}
          >
            <Image src={everysport} order={1} />
            <Stack
              gap="m"
              alignItems="flex-start"
              width={['100%', null, '40%']}
              paddingY={[null, null, 'xxxl']}
            >
              <Text as="h2" color="white400" family="Athletics-Black" size="32">
                One platform for <Text color="watermelon400">every sport.</Text>
              </Text>
              <Text as="p" color="white400">
                Capable of handling thousands of games and millions of players
                at a time, PlayHQ leverages powerful integrations, intuitive
                design and rich data to create an all-in-one software solution
                that streamlines every aspect of community sport administration.
              </Text>
              <HomeLink href="/au/" rel="noopener noreferrer">
                See how it works
              </HomeLink>
            </Stack>
          </Stack>
        </div>
      </Stack>

      <CourtBox>
        <Stack
          gap="xl"
          maxWidth="62rem"
          width="100%"
          marginX="auto"
          alignItems="flex-start"
        >
          <Text
            color="white400"
            size="36"
            weight="900"
            family="Athletics-Black"
            width={['100%', '100%', '55%']}
          >
            Join the ranks of Australia's biggest and best sporting
            organisations.
          </Text>
          <HomeLink href="/au/contact" rel="noopener noreferrer">
            Get PlayHQ today
            <Icon name="right-arrow-strong" color="white400" />
          </HomeLink>
        </Stack>
      </CourtBox>
    </>
  )
}

export default Home
